<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Crear producto
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="createProduct">
        <b-form
          class="mt-2"
          ref="errorField"
          @submit.prevent="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="'Categoría'"
                label-for="product-category"
              >
                <v-select
                  v-model="parent_category"
                  label="name"
                  :options="selectCategoriesParent"
                  :filterable="false"
                  :searchable="false"
                  :placeholder="'Seleccionar categoría'"
                >
                </v-select>

              </b-form-group>
              <b-form-group
                  :label="'Subcategoría'"
                  label-for="product-subcategory"
              >
                <v-select
                    v-model="category"
                    label="name"
                    :options="selectCategories"
                    :filterable="false"
                    :searchable="false"
                    :placeholder="'Seleccionar subcategoría'"
                    :disabled="!parent_category"
                >
                </v-select>

              </b-form-group>
              <BaseInputText
                v-model="name"
                label="Nombre"
                placeholder="Nombre"
                rules="required"
                localize
              />
              <BaseInputText
                v-if="parent_category.ref !== 'extras'"
                v-model="modelo"
                label="Objetivo / Modelo"
                placeholder="Modelo"
                rules="required"
                localize
              />
              <BaseInputText
                v-if="parent_category.ref === 'cuadro-de-control'"
                v-model="amperios"
                label="Amperios"
                placeholder="Amperios"
                rules="required"
              />
              <BaseInputText
                v-model="url_web"
                label="Web"
                placeholder="Web"
                localize
              />
              <BaseEditor
                v-model="description"
                label="Descripción"
                localize
              />
              <BaseEditor
                v-model="objetives"
                label="Objetivos"
                localize
              />
              <BaseEditor
                v-model="characteristics"
                label="Caracteristicas"
                localize
              />
            </b-col>
            <b-col sm="6">
              <b-form-group
                  v-if="parent_category.ref === 'extras'"
                  :label="'Tipo precio'"
                  label-for="product-tipe-price"
              >
                <v-select v-model="tipePrice" label="name" :filterable="false" :searchable="false"
                          :options="selectTipePrice" :placeholder="'Tipo precio'" />
              </b-form-group>
              <BaseInputText
                v-model="price"
                label="Precio"
                placeholder="Precio"
                :disabled="category.name === 'Cortinas de Humo' || category.name === 'Cortinas cortafuegos'"
              />
              <b-form-group
                :label="'Imagen'"
                label-for="product-image"
              >
                <ImageDropzone
                  ref="imagen"
                  max-files="1"
                />
              </b-form-group>
              <b-form-group
                :label="'Documentos'"
                label-for="product-documents"
              >
                <ImageDropzone
                  ref="documents"
                  max-files="10"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1 text-uppercase"
              >
                {{ 'Crear' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import FormService from '@/shared/service/form-service'
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import BaseInputText from '@/components/ui/input/BaseInputText.vue'
import BaseEditor from '@/components/ui/editor/BaseEditor.vue'
import {config} from '@/shared/app.config';

export default {
  components: {
    BaseEditor,
    BaseInputText,
    ValidationObserver,
    vSelect,
    ImageDropzone,
  },
  data() {
    return {
      name: '',
      description: '',
      characteristics: '',
      objetives: '',
      category: '',
      parent_category: '',
      files: [],
      image: '',
      modelo: '',
      price: '',
      calculator: false,
      amperios: '',
      url_web: '',
      tipePrice: config.tipePriceSelect[0],
      selectTipePrice: config.tipePriceSelect,
    }
  },
  computed: {
    ...mapGetters({
      selectCategories: 'categories/getSelectCategories',
      selectCategoriesParent: 'categories/getSelectCategoriesParent',
      languages: 'languages/getLanguages',
    }),
  },
  async created() {
    await this.getSelectCategoriesParent({ parent: 0 })
  },
  methods: {
    ...mapActions({
      create: 'products/create',
      getSelectCategories: 'categories/getSelectCategories',
      getSelectCategoriesParent: 'categories/getSelectCategories',
    }),
    async handleSubmit() {
      const valid = await this.$refs.createProduct.validate()
      if (!valid) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
        block: 'end', 
        });
        return
      }

      this.create({ product: this.createFormData() })
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')
      this.$refs.imagen.getFormData('image', data)

      FormService.appendEntry(data, this.name, 'name')
      FormService.appendEntry(data, this.description, 'description')
      FormService.appendEntry(data, this.objetives, 'objetives')
      FormService.appendEntry(data, this.characteristics, 'characteristics')
      FormService.appendEntry(data, this.calculator ? 1 : 0, 'calculator')
      FormService.appendEntry(data, this.amperios, 'amperios')
      FormService.appendEntry(data, this.url_web, 'url_web')
      FormService.appendEntry(data, this.category?.id, 'category_id')
      FormService.appendEntry(data, this.getModel(), 'modelo')
      FormService.appendEntry(data, this.tipePrice.value, 'tipe_price')

      if (this.parent_category.ref !== 'cortinas') {
        FormService.appendEntry(data, this.price, 'price')
      }
      if (this.parent_category.ref !== 'extras') {
        FormService.appendEntry(data, this.getModel(), 'modelo')
      } else {
        FormService.appendEntry(data, 'Extra', 'modelo')
      }
      if (this.parent_category.ref === 'cortinas') {
        if (this.category.ref === 'cortinas-de-humo') {
          FormService.appendEntry(data, 'cortina humo', 'type')
        } else if (this.category.ref === 'cortinas-cortafuegos') {
          FormService.appendEntry(data, 'cortina cortafuego', 'type')
        }
      } else if (this.parent_category.ref === 'extras') {
        FormService.appendEntry(data, 'extra', 'type')
      } else if (this.parent_category.ref === 'cuadro-de-control') {
        FormService.appendEntry(data, 'cuadro control', 'type')
      }

      return data
    },
    getModel() {
      if (this.category.name === this.$t('Extras')) {
        const model = {}
        this.languages.forEach(lang => {
          model[lang] = this.$t('Extras', lang, null)
        })

        return model
      }

      return this.modelo
    },
  },
  watch: {
    parent_category() {
      if (this.parent_category) {
        this.getSelectCategories(this.parent_category.id)
        this.category = ''
      }
    },
  },
}
</script>

<style lang="scss">

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
